import React from "react";

type Props = {
  height?: number;
  onClick?: () => void;
};
const BeerIcon: React.FC<Props> = ({ height = 120, onClick }: Props) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="299.84"
      height={height}
      version="1.2"
      viewBox="0 0 224.88 112.5"
    >
      <defs>
        <clipPath id="clip1">
          <path d="M23.422 17H111v95H23.422zm0 0"></path>
        </clipPath>
        <clipPath id="clip2">
          <path d="M23.422 17H76v62H23.422zm0 0"></path>
        </clipPath>
        <clipPath id="clip3">
          <path d="M23.422 17H77v62H23.422zm0 0"></path>
        </clipPath>
        <clipPath id="clip4">
          <path d="M112 17h89.133v95H112zm0 0"></path>
        </clipPath>
        <clipPath id="clip5">
          <path d="M146 15h55.133v61H146zm0 0"></path>
        </clipPath>
      </defs>
      <g>
        <g clipPath="url(#clip1)">
          <path
            fill="#C2CBD2"
            d="M44.992 81.164c-10.238-2.379-16.601-12.61-14.226-22.867C33.14 48.039 43.352 41.66 53.59 44.043zm63.735-50.867L87.715 19.52c-6.992-1.758-14.309-2.286-21.094-1.395-4.062.535-7.531 2.18-8.387 5.875l-3.093 13.363c-13.93-3.238-27.813 5.434-31.043 19.383-3.23 13.953 5.422 27.86 19.347 31.102l-1.289 5.57c-.855 3.687 1.54 6.7 4.95 8.969 10.828 7.21 25.644 10.656 38.535 8.957 4.058-.535 7.53-2.18 8.386-5.871l16.078-69.414c.5-2.157-.109-4.082-1.378-5.762"
          ></path>
        </g>
        <path
          fill="#FF9E5E"
          d="M107.29 34.727L92.354 99.215c-.761 3.277-3.835 5.062-7.449 5.543-11.453 1.508-24.625-1.555-34.246-7.965-3.035-2.02-5.012-4.977-4.25-8.262l10.317-44.543c.472-2.05 1.601-3.539 5.082-5.078 4.918-1.82 9.015-4.246 12.55-6.89h-.004c11.399 4.296 26.946 6.265 32.934 2.707"
        ></path>
        <path
          fill="#FFF"
          d="M106.652 50.977c-12.32 2.613-25.66-.489-38.484-5.817a1.526 1.526 0 01-1.14-1.828h.003a1.52 1.52 0 011.824-1.14c12.825 5.328 26.16 8.43 38.48 5.812l-.683 2.973M81.34 59.102c.14-.618.758-.997 1.37-.86.614.145.993.762.853 1.375L75.828 93.02c-.762 3.285-4.031 5.328-7.305 4.566a6.095 6.095 0 01-4.554-7.316l7.738-33.41a1.141 1.141 0 112.223.519l-7.735 33.41a3.806 3.806 0 002.84 4.57c2.05.473 4.09-.8 4.57-2.847l7.735-33.41M97.105 58.465c.145-.617.754-1 1.372-.86.613.145.996.758.851 1.375L91.25 93.867a6.079 6.079 0 01-7.305 4.567 6.093 6.093 0 01-4.55-7.317l7.39-31.922c.145-.617.754-1 1.371-.86.614.145.996.759.852 1.372l-7.39 31.926a3.804 3.804 0 002.843 4.574c2.047.48 4.09-.8 4.562-2.852l8.082-34.89M60.055 49.848a1.144 1.144 0 00-.856-1.371 1.138 1.138 0 00-1.367.859l-8.082 34.89a6.095 6.095 0 004.555 7.317c3.273.762 6.543-1.281 7.304-4.566L69 55.059a1.137 1.137 0 00-.855-1.371 1.136 1.136 0 00-1.368.851l-7.394 31.926a3.804 3.804 0 01-4.567 2.851 3.814 3.814 0 01-2.843-4.574l8.082-34.894"
        ></path>
        <path
          fill="#FDF1CD"
          d="M118.617 2.531c.113-1.808-2.969-2.73-4.754-2.488-2.754.379-5.058 2.25-5.168 4.055-.113 1.804 2.04 2.312 4.785 1.937 3.844-.305 5.024-1.7 5.137-3.504M105.344 11.059c.156.742 1.511.761 2.203.457 1.074-.465 1.781-1.485 1.621-2.227-.16-.742-1.086-.703-2.152-.234-1.52.562-1.832 1.261-1.672 2.004"
        ></path>
        <path
          fill="#FDF1CD"
          d="M74.36 32.02c12.421-9.293 17.816-21.254 27.05-19.106 2.957.688 2.844 4.57 6.153 3.777 5.304-2.68 10.042-6.273 14.886-3.582 2.047 1.262 2.278 3.66.793 3.313-4.094-.953-13.457 7.535-15.93 18.207l-.023.098c-5.988 3.558-21.535 1.59-32.934-2.707"
        ></path>
        <path
          fill="none"
          stroke="#FFF"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="20"
          d="M1114.818 1022.719c-102.802-89.083-560.638 17.17-613.733 142.388-7.805 18.4 40.566 41.876 91.556 40.39"
          transform="matrix(.07607 0 0 -.07621 23.42 111.997)"
        ></path>
        <g clipPath="url(#clip2)">
          <path
            fill="none"
            stroke="#FFF"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="20"
            d="M40.22 727.486c37.537 161.507 198.518 261.916 360.116 224.397"
            transform="matrix(.07607 0 0 -.07621 23.42 111.997)"
          ></path>
        </g>
        <g clipPath="url(#clip3)">
          <path
            fill="none"
            stroke="#FFF"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="20"
            d="M49.976 725.18c37.486 161.557 198.519 261.916 360.065 224.448"
            transform="matrix(.07607 0 0 -.07621 23.42 111.997)"
          ></path>
        </g>
        <path
          fill="#FFF"
          d="M56.676 30.715c.054 3.144 2.176 7.156 5.12 8.234.794.285 1.329 1.008 1.142 1.828-.192.82-1.04 1.43-1.825 1.141-2.949-1.082-5.242-4.348-5.468-6.75l1.03-4.453"
        ></path>
        <g clipPath="url(#clip4)">
          <path
            fill="#C2CBD2"
            d="M180.73 79.172l-10.94-36.496c10.065-3.028 20.66 2.68 23.679 12.761 3.031 10.09-2.668 20.704-12.739 23.735zm-12.91-43.067L163.88 22.97c-1.086-3.633-4.656-5.051-8.75-5.324-10.32-.696-21.656 1.94-31.066 7.023l-10.223 7.898c-1.125 1.735-1.582 3.664-.961 5.754l20.465 68.243c1.09 3.628 4.656 5.054 8.75 5.328 12.969.867 27.535-3.512 37.883-11.403 3.261-2.484 5.453-5.644 4.367-9.273l-1.645-5.473c13.692-4.12 21.446-18.558 17.328-32.273-4.105-13.719-18.515-21.48-32.207-17.364"
          ></path>
        </g>
        <path
          fill="#FDF1CD"
          d="M150.164 31.117c-18.473-4.094-28.976-9.34-35.465-5.281-4.695 2.937-.875 5.039.875 10.875l.031.098c6.555 3.351 23.391-.149 34.56-5.688"
        ></path>
        <path
          fill="#FF9E5E"
          d="M115.574 36.71l.031.099 19.016 63.398c.969 3.23 4.149 4.816 7.781 5.063 11.528.77 24.48-3.125 33.672-10.137 2.899-2.211 4.676-5.29 3.711-8.516L165.56 39.172c-.606-2.016-2.7-6.348-7.508-6.492a177.523 177.523 0 01-7.887-1.563v.004c-11.168 5.54-28.004 9.04-34.559 5.688"
        ></path>
        <path
          fill="#FFF"
          d="M117.277 52.988c12.461 1.817 25.575-2.129 38.035-8.265a1.524 1.524 0 001.02-1.899h-.008a1.51 1.51 0 00-1.887-1.023c-12.46 6.14-25.574 10.086-38.035 8.265l.875 2.922M143.059 59.477a1.146 1.146 0 00-1.43-.766c-.602.184-.945.82-.762 1.422l9.852 32.851a6.082 6.082 0 007.578 4.082 6.1 6.1 0 004.074-7.597l-9.848-32.844a1.137 1.137 0 00-1.418-.766c-.609.18-.949.82-.765 1.422l9.851 32.844a3.806 3.806 0 01-2.55 4.746 3.797 3.797 0 01-4.739-2.55l-9.843-32.844M127.281 59.848a1.141 1.141 0 10-2.183.66l10.285 34.304a6.087 6.087 0 007.578 4.086 6.1 6.1 0 004.074-7.601l-9.41-31.379c-.18-.605-.82-.95-1.422-.77-.601.184-.941.82-.766 1.426l9.418 31.387a3.825 3.825 0 01-2.55 4.75 3.811 3.811 0 01-4.739-2.555l-10.285-34.308M163.703 48.883a1.15 1.15 0 01.77-1.426 1.146 1.146 0 011.422.77l10.285 34.308a6.098 6.098 0 01-4.078 7.59 6.077 6.077 0 01-7.575-4.086l-9.418-31.387a1.147 1.147 0 01.766-1.422c.602-.18 1.242.16 1.422.766l9.41 31.379a3.814 3.814 0 004.742 2.563 3.822 3.822 0 002.547-4.75l-10.293-34.305"
        ></path>
        <path
          fill="none"
          stroke="#FFF"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="20"
          d="M1198.98 994.528c3.8-2.922 8.114-5.587 12.838-7.945 86.165-43.977 307.483 1.948 454.292 74.629 69.476 34.392 122.315 74.884 136.18 114.607 6.624 18.862-43.083 39.262-93.868 34.393"
          transform="matrix(.07607 0 0 -.07621 23.42 111.997)"
        ></path>
        <g clipPath="url(#clip5)">
          <path
            fill="none"
            stroke="#FFF"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="20"
            d="M2280.614 765.57c-47.704 158.84-214.797 248.743-373.622 201.024"
            transform="matrix(.07607 0 0 -.07621 23.42 111.997)"
          ></path>
        </g>
        <path
          fill="#FFF"
          d="M165.855 29.574c.153 3.137-1.71 7.274-4.585 8.543-.77.34-1.262 1.09-1.012 1.895.234.808 1.117 1.363 1.887 1.023 2.875-1.265 4.96-4.676 5.027-7.082l-1.317-4.379"
        ></path>
      </g>
    </svg>
  );
};

export default BeerIcon;
