import React from "react";

export const Arrow: React.FC = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.45741 9.79057L14.1147 4.13323C14.6148 3.63372 15.2926 3.35314 15.9994 3.35314C16.7062 3.35314 17.3841 3.63372 17.8841 4.13323L23.5427 9.79057C25.0344 11.2823 26.0503 13.183 26.4618 15.2521C26.8734 17.3212 26.6621 19.4659 25.8547 21.4149C25.0474 23.364 23.6802 25.0298 21.9261 26.2019C20.172 27.3739 18.1097 27.9995 16.0001 27.9995C13.8904 27.9995 11.8282 27.3739 10.0741 26.2019C8.31994 25.0298 6.95276 23.364 6.14541 21.4149C5.33806 19.4659 5.1268 17.3212 5.53833 15.2521C5.94986 13.183 6.9657 11.2823 8.45741 9.79057V9.79057Z"
        fill="#FF0000"
        stroke="white"
        strokeWidth="1.33333"
      />
      <path
        d="M16 13.3335C13.7909 13.3335 12 15.1244 12 17.3335C12 19.5426 13.7909 21.3335 16 21.3335C18.2091 21.3335 20 19.5426 20 17.3335C20 15.1244 18.2091 13.3335 16 13.3335Z"
        fill="white"
        stroke="white"
        strokeWidth="1.33333"
      />
    </svg>
  );
};
